// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

/* 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
});*/

//Menu
var state = false;
var pos;
$("#menu-btn").on("click",function(){
  if (state == false) {
    pos = $(window).scrollTop();
    $('body').addClass('fixed').css({'top': -pos});
    state = true;
  } else {
    $('body').removeClass('fixed').css({'top': 0});
    window.scrollTo(0, pos);
    state = false;
  }

  $('.header-menu-btn-inner').toggleClass("open");
  $("#menu").toggleClass("open");
});

// 最新治験情報
$(function() {
  if($("#home").length){
    $('.top-info-tab').on('click', function() {
      $('.top-info-tab, .top-info-panel').removeClass('active');
  
      $(this).addClass('active');
      
      var index = $('.top-info-tab').index(this);
      $('.top-info-panel').eq(index).addClass('active');
    });
  }
});


// オープニングアニメーション
if($("#home").length){
  const kvCopy = "#kvCopy";
  const kvText = "#kvText";
  const kvFukidashi = "#kvFukidashi";

  gsap.set(kvCopy, {
    opacity: 0,
    y: 0,
    ease: "power4.out",
  });
  gsap.set(kvText, {
    opacity: 0,
    y: 0,
    ease: "power4.out",
  });
  gsap.set(kvFukidashi, {
    opacity: 0,
    y: 0,
    ease: "power4.out",
  });


  const tl = gsap.timeline();

  tl.to(
    kvCopy, 
    {
      opacity: 1,
      duration: 1,
    }
  )
  .to(
    kvText, 
    {
      opacity: 1,
      duration: 0.8,
    },
    "+=0.4"
  )
  .to(
    kvFukidashi, 
    {
      opacity: 1,
      duration: 0.6,
    },
    "+=0.1"
  );
}